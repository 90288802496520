import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { Link } from 'react-router-dom';

const News = () => {
	const { t } = useTranslation();

	const newsData = {
		exchangeNews: [
			{
				id: 1,
				date: '10.05.2024',
				title: t('news.exchange.generalMeeting2024'),
				href: '/assets/news/Газета ПДФ 10 мая 2024, 10  стр.pdf#page=10',
			},
			{
				id: 2,
				date: '14.11.2023',
				title: t('news.exchange.extraordinaryMeeting2023November'),
				href: '/assets/news/эркин тоо газета.pdf#page=14',
			},
			{
				id: 3,
				date: '20.10.2023',
				title: t('news.exchange.extraordinaryMeeting2023October'),
				href: '/assets/news/Газета эркин тоо.pdf#page=10',
			},
		],
		companyNews: [
			{
				id: 1,
				date: '30.07.2024',
				title: t('news.company.royalQ2'),
				href: '/assets/news/royal.pdf',
			},
			{
				id: 2,
				date: '30.10.2024',
				title: t('news.company.royalQ3'),
				href: '/assets/news/royal_2.pdf',
			},
			{
				id: 3,
				date: '03.12.2024',
				title: t('news.company.keregitashFact'),
				href: '/assets/news/Керегиташ_сущ_факт.pdf',
			},
			{
				id: 4,
				date: t('companyNews.news.ascorpFact.date'),
				title: t('companyNews.news.ascorpFact.title'),
				href: '/assets/news/ascorp.pdf',
			},
			{
				id: 5,
				date: t('companyNews.news.skynetFact.date'),
				title: t('companyNews.news.skynetFact.title'),
				href: '/assets/news/skynet.pdf',
			},
			{
				id: 6,
				date: t('companyNews.news.flyAsiaFact.date'),
				title: t('companyNews.news.flyAsiaFact.title'),
				href: '/assets/news/fly_asia.pdf',
			},
			{
				id: 7,
				date: t('companyNews.news.softikFact.date'),
				title: t('companyNews.news.softikFact.title'),
				href: '/assets/news/softik.pdf',
			},
			{
				id: 8,
				date: t('companyNews.news.stbFact.date'),
				title: t('companyNews.news.stbFact.title'),
				href: '/assets/news/stb.pdf',
			},
			{
				id: 9,
				date: t('companyNews.news.pogrebokFact.date'),
				title: t('companyNews.news.pogrebokFact.title'),
				href: '/assets/news/pogrebok.pdf',
			},
			{
				id: 10,
				date: t('companyNews.news.otdelochnikFact.date'),
				title: t('companyNews.news.otdelochnikFact.title'),
				href: '/assets/news/otdelochnik.pdf',
			},
			{
				id: 11,
				date: t('companyNews.news.metallistFact.date'),
				title: t('companyNews.news.metallistFact.title'),
				href: '/assets/news/metallist.pdf',
			},
			{
				id: 12,
				date: t('companyNews.news.lesopovalFact.date'),
				title: t('companyNews.news.lesopovalFact.title'),
				href: '/assets/news/lesopoval.pdf',
			},
			{
				id: 13,
				date: t('companyNews.news.cybersecFact.date'),
				title: t('companyNews.news.cybersecFact.title'),
				href: '/assets/news/cybersec.pdf',
			},
			{
				id: 14,
				date: '29.01.2025',
				title: t('companyNews.news.royalQ4.title'),
				href: '/assets/news/royal_3.pdf',
			},
		],
	};

	const reversedCompanyNews = useMemo(
		() => [...newsData.companyNews].reverse().slice(0, 3),
		[newsData.companyNews]
	);

	return (
		<div className='news-block'>
			<div className='container'>
				<h2 className='news-block__title title'>{t('news.title')}</h2>
				<div className='news-block__columns'>
					<div className='news-block__column'>
						<h3 className='news-block__subtitle'>{t('news.exchange.title')}</h3>
						<ul className='news-block__list'>
							{newsData.exchangeNews.map((news) => (
								<li key={news.id} className='news-block__item'>
									<span className='news-block__date'>{news.date}</span>
									<a target='_blank' rel='noreferrer' href={news.href}>
										{news.title}
									</a>
								</li>
							))}
						</ul>
						<Link to='exchange-news' className='news-block__link'>
							{t('news.viewAll')} →
						</Link>
					</div>

					<div className='news-block__column'>
						<h3 className='news-block__subtitle'>{t('news.company.title')}</h3>
						<ul className='news-block__list'>
							{reversedCompanyNews.map((news) => (
								<li key={news.id} className='news-block__item'>
									<span className='news-block__date'>{news.date}</span>
									<a target='_blank' rel='noreferrer' href={news.href}>
										{news.title}
									</a>
								</li>
							))}
						</ul>
						<Link to='company-news' className='news-block__link'>
							{t('news.viewAll')} →
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default News;
