import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import NewsImage1 from '../../../images/royal-news.png';
import NewsImage2 from '../../../images/royal-news2.png';
import NewsImage3 from '../../../images/keregi-news.png';

const CompanyNews = () => {
	const { t } = useTranslation();

	const news = [
		{
			title: t('companyNews.news.royalQ2.title'),
			desc: '',
			date: t('companyNews.news.royalQ2.date'),
			image: '',
			link: '/assets/news/royal.pdf',
			logo: '/assets/company_logos/rpg.png',
		},
		{
			title: t('companyNews.news.royalQ3.title'),
			desc: '',
			date: t('companyNews.news.royalQ3.date'),
			image: '',
			link: '/assets/news/royal_2.pdf',
			logo: '/assets/company_logos/rpg.png',
		},
		{
			title: t('companyNews.news.keregitashFact.title'),
			desc: '',
			date: t('companyNews.news.keregitashFact.date'),
			image: '',
			link: '/assets/news/Керегиташ_сущ_факт.pdf',
			logo: '',
		},
		{
			title: t('companyNews.news.ascorpFact.title'),
			desc: '',
			date: t('companyNews.news.ascorpFact.date'),
			image: '',
			link: '/assets/news/ascorp.pdf',
			logo: '',
		},
		{
			title: t('companyNews.news.skynetFact.title'),
			desc: '',
			date: t('companyNews.news.skynetFact.date'),
			image: '',
			link: '/assets/news/skynet.pdf',
			logo: '',
		},
		{
			title: t('companyNews.news.flyAsiaFact.title'),
			desc: '',
			date: t('companyNews.news.flyAsiaFact.date'),
			image: '',
			link: '/assets/news/fly_asia.pdf',
			logo: '',
		},
		{
			title: t('companyNews.news.softikFact.title'),
			desc: '',
			date: t('companyNews.news.softikFact.date'),
			image: '',
			link: '/assets/news/softik.pdf',
			logo: '/assets/company_logos/softik.png',
		},
		{
			title: t('companyNews.news.stbFact.title'),
			desc: '',
			date: t('companyNews.news.stbFact.date'),
			image: '',
			link: '/assets/news/stb.pdf',
			logo: '',
		},
		{
			title: t('companyNews.news.pogrebokFact.title'),
			desc: '',
			date: t('companyNews.news.pogrebokFact.date'),
			image: '',
			link: '/assets/news/pogrebok.pdf',
			logo: '/assets/company_logos/pogrebok.svg',
		},
		{
			title: t('companyNews.news.otdelochnikFact.title'),
			desc: '',
			date: t('companyNews.news.otdelochnikFact.date'),
			image: '',
			link: '/assets/news/otdelochnik.pdf',
			logo: '/assets/company_logos/otdelochnik.png',
		},
		{
			title: t('companyNews.news.metallistFact.title'),
			desc: '',
			date: t('companyNews.news.metallistFact.date'),
			image: '',
			link: '/assets/news/metallist.pdf',
			logo: '/assets/company_logos/metallist.svg',
		},
		{
			title: t('companyNews.news.lesopovalFact.title'),
			desc: '',
			date: t('companyNews.news.lesopovalFact.date'),
			image: '',
			link: '/assets/news/lesopoval.pdf',
			logo: '/assets/company_logos/lesopoval.png',
		},
		{
			title: t('companyNews.news.cybersecFact.title'),
			desc: '',
			date: t('companyNews.news.cybersecFact.date'),
			image: '',
			link: '/assets/news/cybersec.pdf',
			logo: '/assets/company_logos/cybersec.png',
		},
		{
			title: t('companyNews.news.royalQ4.title'),
			desc: '',
			date: t('companyNews.news.royalQ4.date'),
			image: '',
			link: '/assets/news/royal_3.pdf',
			logo: '/assets/company_logos/rpg.png',
		},
	];

	return (
		<>
			<section className='news'>
				<div className='container'>
					<h1 className='news__title title'>{t('companyNews.title')}</h1>
					<div className='news__content'>
						{[...news].reverse().map((newsItem, index) => (
							<div key={newsItem.id || index} className='news__col'>
								<a
									href={newsItem.link}
									target='_blank'
									className='news__card'
									rel='noopener noreferrer'
								>
									<div className='news__card-image--block'>
										{newsItem.logo ? (
											<div className='news__card-placeholder'>
												<img
													className='news__card-logo'
													src={newsItem.logo}
													alt='logo'
												/>
												{newsItem.title}
											</div>
										) : (
											<div className='news__card-placeholder'>
												{newsItem.title}
											</div>
										)}
									</div>
									<span className='news__card-date'>{newsItem.date}</span>
									<h3 className='news__card-title'>{newsItem.title}</h3>
								</a>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default CompanyNews;
